//.ant-layout-header {
//  background-color: #fff;
//}

div.ant-page-header {
  margin-bottom: 20px;
  //border: 1px solid rgb(235, 237, 240);
}

.container {
  padding: 24px;
  //background: #fff;
  min-height: 360px;
}

.separator {
  margin: 16px 0px;
}

.separator-2 {
  margin: 0px 16px;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.item-inline {
  display: flex;
  align-items: center;

  >span {
    margin-right: 10px;
  }

  >.ant-form-item {
    margin-bottom: 0;
  }

}

.ant-menu-item,
.ant-menu-submenu-title {
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

//.logo {
//  color: #fff;
//  font-size: 1.5rem;
//  padding-left: 1rem;
//}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;

  &:hover {
    color: #777;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.list-custom {
  list-style-type: none;
  padding-left: 0;

  >li {
    &.mb {
      >div {
        margin-bottom: 12px;
      }
    }

    >div {
      display: flex;
      border: 1px solid transparent;
      border-color: #d9d9d9;
      padding: 5px 15px;
      cursor: default;
      font-size: 14px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fff;
      align-items: center;
      justify-content: space-between;

      >span {
        display: inline-block;
        padding: 2px 7px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 5px;
      }
    }

  }
}

/*------------------------------------
  Layout
------------------------------------*/
//Flex
.d-flex {
  display: flex;
}
.justify-content-start {
  justify-content: flex-start!important;
}
.justify-content-end {
  justify-content: flex-end!important;
}
.justify-content-center {
  justify-content: center!important;
}
.justify-content-between {
  justify-content: space-between!important;
}
.justify-content-around {
  justify-content: space-around!important;
}
.d-block {
  display: block;
}
.float-right {
  float: right;
}

/*------------------------------------
  Components
------------------------------------*/
.custom-ql-editor {
  .ql-editor {
    height: 150px !important;
  }
}

.i-language-selector {
  button {
    font-size: 14px !important;
    height: 32px;
    background: rgba( #3092F9, .5 );
    color: #fff;
    > span {
      border-right: 1px solid #fff;
    }
    &:after {
      border-top: 5px solid #fff;
    }
    &[aria-expanded="true"]:after {
      border-bottom:5px solid #fff !important;
    }
  }
  ul {
    font-size: 14px !important;
  }
}